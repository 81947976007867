.category_container{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories_title{
    font-size: 3rem;
    font-weight: bold;
    margin:20px 0px;
}

.categories_link{
    padding:0 20px;

}

.cat_inside{
    display: flex;
}

.categories_link_single{
    margin:0.3rem 0;
    display: inline-block;
    padding:0.5rem;
    background-color: #3B82F6;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    border-radius: 0.25rem;
    text-transform: uppercase;
}