@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');


.grid-items{
    /* background-color: rgba(255, 255, 255, 0.8); */
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-top:2px solid rgb(212, 212, 212);
    padding-top:2rem;
}



.left{
    font-size: 18px;
    width:30%;
    margin-top: 1rem;
}

.right{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.title{
    color: black;
    font-size: 2.2rem;
    margin:0.3rem 0;
}

.title:hover{
    text-decoration: underline;
    text-decoration-color: #3B82F6;
}

.date{
    margin:0.3rem 0;
    color:#aaadb5;
    font-family: 'IBM Plex Sans', sans-serif;
}

.cat{
    margin:0.3rem 0;
    display: inline-block;
    padding:0.5rem;
    background-color: #3B82F6;
    color: white;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    border-radius: 0.25rem;
}

.desc{
    margin:0.3rem 0;
}

.read_more_inner{
    color: #3B82F6; 
    font-size:1.2rem;
    margin:0.3rem 0;
    border-radius: 0.25rem;
    padding:4px;
}
.read_more_inner:hover{
    text-decoration: underline;
    text-decoration-color: #3B82F6;
}

@media (max-width: 640px) {
    .title {
        font-size: 1.5rem;
    }
    .grid-items{
        flex-direction: column;
    }
} 