.update-container {
    height: 150vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.update-title{
    width: 50vw;
    margin-bottom: 50px;
}

.update-content {
    margin: 20px;
}

.update-field {
    margin-top: 20px;
}

#update-name, #update-age, #update-add, #update-mail, #update-phone {
    align-items: center;
    text-align: left;
}

#update-name {
    padding-top: 20px;
}

#update-phone {
    padding-bottom: 20px;
}

#update-userName, #update-userAge, #update-userAdd, #update-userMail, #update-userPhone {
    display: flex;
    width: 95%;
    margin-top:10px;
}

.update-input{
    padding: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    border-radius:4px;
    border:1px solid black;
}

.update-btn{
    border: none;
    border-radius: 4px;
    height:40px;
    width:90px;
    background: blue;
    font-size:16px;
    cursor: pointer;
    color: white;
    margin-top:20px;
}