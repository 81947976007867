body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:1rem 2rem;  
}


a {
  text-decoration: none;
  
}

pre{
  max-width:90%;
  word-wrap: break-word;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  word-wrap: break-word;
}

@media screen and (max-width: 600px) {
  pre{
    max-width:500px;
  }
}

@media screen and (max-width: 550px) {
  pre{
    max-width:300px;
  }
}

img{
  max-width:90%;
}


.flex{
  display: flex;
}

.justify-center{
  justify-content: center;
}

.justify-around{
  justify-content: space-around;
}

.items-center{
  align-items: center;
}
.bg-red{
  background-color: red;
}

.trans1{
  transform: translateX(-35rem);
}

.trans2{
  transform: translateX(0rem);
}

.side1{
  transform: translateX(-35rem);
}

.side2{
  transform: translateX(0rem);
}

.flexx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-white{
  color: white;
}

.color-black{
  color: black;
}


.error{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 3rem;
  font-weight: bold;
}