.blog-container{
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
}

.blog-content{
    max-width: 100%;
    min-width: 100%
}

.main{
    width: 50%;
}

.back a{
    color:black;
}

.blogData{
    word-wrap: break-word;
}

@media (max-width: 640px) {
    .main{
        width: 90%;
    }
} 
