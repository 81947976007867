footer{
    margin-top:20px;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.svg{
    width: 20px;
    padding: 10px;
}

.socials{
    display: flex;
}

.footer-copyright-content{
    padding:0 10px
}