body{
    margin:0;
    padding:0;
}

.sidebar-aside{
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    width: 97.5vw;
    padding:10px;
    overflow: hidden;
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.sidebar-ul{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-main{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.sidebar-main-title{
    font-size:2rem;
    font-weight: bold;
}

.sidebar-li{
    font-size:1.2rem;
}
.sidebar-links, .sidebar-title{
    color:black;
}

.sidebar-links{
    margin-left:40px;
    margin-right:40px;
}

.sidebar-main-title{
    margin-left:40px;
}

.sidebar-links:hover{
    color:blue;
}

