.login-main {
    /* background-color:aqua; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.login-login {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-top {
    font-size: 3rem;
    font-weight: bold;
}

.login-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.login-label {
    font-size: 1.2rem;
    margin-top: 20px;
}

.login-input {
    width: 250px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid black;
    outline: none;
    padding:4px;
}

.login-btn {
    cursor: pointer;
    background: blue;
    color:white;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;
    font-size: 1.2rem;
}