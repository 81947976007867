@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');


.main_nav {
    height: 8rem;
}

.navbar_left {
    font-size: 28px;
    font-weight: bold;
    color: black;
}

.navbar_right {
    width: 22rem;
}

.navbar_ul {
    text-decoration: none;
    list-style: none;
}

.navbar_ul li a {
    color: black;
    font-size: 17px;
    font-weight: 400;
}

.bg-green {
    background-color: green;
}

.bg-yellow {
    background-color: yellow;
}



.navbar_sidebar_top{
    padding: 3px;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}


.sidebar {
    width: 80%;
    height: 100vh;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    transition: all;
    z-index: 50;
}


.links {
    margin-bottom: 10px;
}

ul.flexx {
    display: flex;
}

.my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.navbar_textstyle {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #111827;
}

.navbar_textstyle:hover {
    color: #2563eb;
}

.humburger_btn {
    display: flex;
    flex-direction: column;
    height: 3rem;
    width: 3rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.border {
    border-width: 1px;
}

.border-gray-400 {
    border-color: #9CA3AF;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.rounded {
    border-radius: 0.375rem;
}

.ml-3 {
    margin-left: 0.75rem;
}

.text-white {
    color: white;
}

.bg-blue-700 {
    background-color: #2563eb;
}

.hover\:bg-blue-800:hover {
    background-color: #1d4ed8;
}

.focus\:ring-blue-300:focus {
    outline-color: #93c5fd;
    outline-style: auto;
    outline-width: 3px;
    outline-offset: 0;
}

.font-medium {
    font-weight: 500;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.text-sm {
    font-size: small;
}

.text-center {
    text-align: center;
}

.ml-3 {
    margin-left: 0.75rem;
}

.duration-500 {
    transition-duration: 0.5s;
}

.mt-4 {
    margin-top: 1rem;
}


ul li {
    list-style: none;
}